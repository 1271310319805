var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("linkbill.header"),
          "prepend-icon": "$g_linkbill"
        }
      }),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c("title-label", {
                  attrs: {
                    label: _vm.$t("linkbill.useCustomerLink.title"),
                    "prepend-icon": "mdi-receipt"
                  }
                }),
                _c("v-switch", {
                  staticClass: "mt-0 ml-6",
                  attrs: { inset: "", "hide-details": "" },
                  on: {
                    change: function($event) {
                      return _vm.submit("useCustomerLink")
                    }
                  },
                  model: {
                    value: _vm.form.useCustomerLink,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "useCustomerLink", $$v)
                    },
                    expression: "form.useCustomerLink"
                  }
                })
              ],
              1
            ),
            _c(
              "span",
              { class: !_vm.form.useCustomerLink ? "disabled--text" : "" },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("linkbill.useCustomerLink.hint")) + " "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "pl-12" },
              [
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(
                      "linkbill.useCustomerLink.whaleboxBillingDomain"
                    ),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("whaleboxBillingDomain")
                    }
                  },
                  model: {
                    value: _vm.form.whaleboxBillingDomain,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "whaleboxBillingDomain", $$v)
                    },
                    expression: "form.whaleboxBillingDomain"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(
                      "linkbill.useCustomerLink.customerLinkRequireSms"
                    ),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("customerLinkRequireSms")
                    }
                  },
                  model: {
                    value: _vm.form.customerLinkRequireSms,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "customerLinkRequireSms", $$v)
                    },
                    expression: "form.customerLinkRequireSms"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t("linkbill.useCustomerLink.liveOrderHoldable"),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("liveOrderHoldable")
                    }
                  },
                  model: {
                    value: _vm.form.liveOrderHoldable,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "liveOrderHoldable", $$v)
                    },
                    expression: "form.liveOrderHoldable"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(
                      "linkbill.useCustomerLink.liveOrderHoldableIfPaid"
                    ),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("liveOrderHoldableIfPaid")
                    }
                  },
                  model: {
                    value: _vm.form.liveOrderHoldableIfPaid,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "liveOrderHoldableIfPaid", $$v)
                    },
                    expression: "form.liveOrderHoldableIfPaid"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(
                      "linkbill.useCustomerLink.showProductImageInOrderLink"
                    ),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("showProductImageInOrderLink")
                    }
                  },
                  model: {
                    value: _vm.form.showProductImageInOrderLink,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "showProductImageInOrderLink", $$v)
                    },
                    expression: "form.showProductImageInOrderLink"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(
                      "linkbill.useCustomerLink.orderLinkTextShowProduct"
                    ),
                    disabled: !_vm.form.useCustomerLink,
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.submit("orderLinkTextShowProduct")
                    }
                  },
                  model: {
                    value: _vm.form.orderLinkTextShowProduct,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "orderLinkTextShowProduct", $$v)
                    },
                    expression: "form.orderLinkTextShowProduct"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.permission.settingCreditCard
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between align-center" },
                  [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("linkbill.acceptCreditCard.title")) +
                          " "
                      )
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          to: "shop-setting/payment?tab=1",
                          color: "primary",
                          outlined: ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("linkbill.btn.activeCreditCard")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }