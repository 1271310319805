<template>
  <div>
    <header-label
      :label="$t('linkbill.header')"
      prepend-icon="$g_linkbill" />
    <v-card
      class="mb-3"
      flat>
      <v-card-text>
        <div class="d-flex mb-4">
          <title-label
            :label="$t('linkbill.useCustomerLink.title')"
            prepend-icon="mdi-receipt" />
          <v-switch
            v-model="form.useCustomerLink"
            class="mt-0 ml-6"
            inset
            hide-details
            @change="submit('useCustomerLink')" />
        </div>
        <span :class="!form.useCustomerLink ? 'disabled--text' : ''">
          {{ $t('linkbill.useCustomerLink.hint') }}
        </span>
        <div class="pl-12">
          <v-checkbox
            v-model="form.whaleboxBillingDomain"
            :label="$t('linkbill.useCustomerLink.whaleboxBillingDomain')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('whaleboxBillingDomain')" />

          <v-checkbox
            v-model="form.customerLinkRequireSms"
            :label="$t('linkbill.useCustomerLink.customerLinkRequireSms')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('customerLinkRequireSms')" />
          <v-checkbox
            v-model="form.liveOrderHoldable"
            :label="$t('linkbill.useCustomerLink.liveOrderHoldable')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('liveOrderHoldable')" />
          <v-checkbox
            v-model="form.liveOrderHoldableIfPaid"
            :label="$t('linkbill.useCustomerLink.liveOrderHoldableIfPaid')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('liveOrderHoldableIfPaid')" />
          <v-checkbox
            v-model="form.showProductImageInOrderLink"
            :label="$t('linkbill.useCustomerLink.showProductImageInOrderLink')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('showProductImageInOrderLink')" />
          <v-checkbox
            v-model="form.orderLinkTextShowProduct"
            :label="$t('linkbill.useCustomerLink.orderLinkTextShowProduct')"
            :disabled="!form.useCustomerLink"
            hide-details
            @change="submit('orderLinkTextShowProduct')" />
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="permission.settingCreditCard"
      flat>
      <v-card-text>
        <div class="d-flex justify-space-between align-center">
          <div>
            {{ $t('linkbill.acceptCreditCard.title') }}
          </div>
          <v-spacer />
          <v-btn
            to="shop-setting/payment?tab=1"
            color="primary"
            outlined>
            {{ $t('linkbill.btn.activeCreditCard') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GET_SETTING_LINKBILL, UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'LinkBill',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_LINKBILL,
      result ({ data: { setting } }) {
        this.form.useCustomerLink = setting.useCustomerLink
        this.form.whaleboxBillingDomain = setting.whaleboxBillingDomain
        this.form.customerLinkRequireSms = setting.customerLinkRequireSms
        this.form.liveOrderHoldable = setting.liveOrderHoldable
        this.form.liveOrderHoldableIfPaid = setting.liveOrderHoldableIfPaid
        this.form.showProductImageInOrderLink = setting.showProductImageInOrderLink
        this.form.orderLinkTextShowProduct = setting.orderLinkTextShowProduct
        this.setLoading(false)
      }
    })
  },
  data () {
    return {
      state: {
        useCustomerLink: 'ready',
        whaleboxBillingDomain: 'ready',
        customerLinkRequireSms: 'ready',
        liveOrderHoldable: 'ready',
        liveOrderHoldableIfPaid: 'ready',
        showProductImageInOrderLink: 'ready',
        orderLinkTextShowProduct: 'ready'
      },
      form: {
        useCustomerLink: null,
        whaleboxBillingDomain: null,
        customerLinkRequireSms: null,
        liveOrderHoldable: null,
        liveOrderHoldableIfPaid: null,
        showProductImageInOrderLink: null,
        orderLinkTextShowProduct: null
      }
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission'
    })
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    async submit (field) {
      this.dialog = false
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>
